$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-external-link: "\e951";
$icon-stack: "\e952";
$icon-location: "\e953";
$icon-kvadrat: "\e94f";
$icon-rok: "\e950";
$icon-tag: "\e94e";
$icon-exit: "\e94b";
$icon-group: "\e94c";
$icon-user: "\e94d";
$icon-calendar: "\e948";
$icon-diploma: "\e949";
$icon-map: "\e94a";
$icon-filter: "\e946";
$icon-clear-filter: "\e947";
$icon-minus: "\e909";
$icon-archive-folder: "\e942";
$icon-download-folder: "\e943";
$icon-open-folder: "\e944";
$icon-folder: "\e945";
$icon-quote: "\e941";
$icon-pdf: "\e926";
$icon-archive: "\e93a";
$icon-txt: "\e93b";
$icon-doc: "\e93c";
$icon-jpg: "\e93d";
$icon-tif: "\e93e";
$icon-png: "\e93f";
$icon-ppt: "\e940";
$icon-language: "\e939";
$icon-youtube: "\e935";
$icon-pinterest: "\e936";
$icon-instagram: "\e937";
$icon-facebook: "\e938";
$icon-error: "\e900";
$icon-account-circle: "\e901";
$icon-add-black: "\e902";
$icon-add-to-photos: "\e903";
$icon-arrow-down: "\e904";
$icon-arrow-up: "\e905";
$icon-assessment: "\e906";
$icon-bookmark: "\e907";
$icon-edit-sketch: "\e908";
$icon-refresh: "\e90a";
$icon-call-black: "\e90b";
$icon-card-giftcard: "\e90c";
$icon-chat: "\e90d";
$icon-check: "\e90e";
$icon-check-box: "\e90f";
$icon-check-box-outline-blank: "\e910";
$icon-check-circle: "\e911";
$icon-chevron-left: "\e912";
$icon-chevron-right: "\e913";
$icon-close: "\e914";
$icon-cloud-download: "\e915";
$icon-cloud-upload: "\e916";
$icon-edit: "\e917";
$icon-credit-card: "\e918";
$icon-delete: "\e919";
$icon-description: "\e91a";
$icon-event: "\e91b";
$icon-chevron-up: "\e91c";
$icon-chevron-down: "\e91d";
$icon-face: "\e91e";
$icon-favorite: "\e91f";
$icon-favorite-border: "\e920";
$icon-paint: "\e921";
$icon-home: "\e922";
$icon-magazine: "\e923";
$icon-info: "\e924";
$icon-insert-photo: "\e925";
$icon-list: "\e927";
$icon-mail: "\e928";
$icon-menu: "\e929";
$icon-more-vert: "\e92a";
$icon-note-add: "\e92b";
$icon-play-circle: "\e92c";
$icon-print: "\e92d";
$icon-clock: "\e92e";
$icon-radio-button-checked: "\e92f";
$icon-radio-button-unchecked: "\e930";
$icon-location1: "\e931";
$icon-search: "\e932";
$icon-settings: "\e933";
$icon-shopping-cart: "\e934";

