//@import 'normalize.css/normalize.css';
@import 'styles/tailwind';
@import 'styles/fonts';
@import 'styles/animation';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  @apply bg-background text-color h-full w-full;

  #app {
    @apply h-full;
    //min-height: calc(100vh - calc(100vh - 100%));
    //height: calc(100vh - calc(100vh - 100%));

    main {
      @apply h-full;
      //min-height: calc(100vh - calc(100vh - 100%));
      padding-top: 4rem;
    }

    &[inert='true'] {
      position: fixed;
      overflow-y: visible;
      width: 100%;
    }
  }
}

:root {
  --primary: #52545d;
  --secondary: #7c5e76;
  --tertiary: #b0c4cc;
  --bg-color: #f1f1f2;
  --border-color: #dcdcdc;
}

.house-title {
  @apply uppercase text-lg font-bold flex flex-col mb-4;

  &:after {
    content: '';
    @apply w-16 h-[5px] bg-primary mt-1;
  }
}

.hide-scrollbars {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

label {
  @apply block text-gray-700 text-sm font-bold mb-2;
}

input[type='checkbox'] {
  @apply appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-secondary-500 checked:border-secondary-500 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
