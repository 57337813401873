@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .sub-title {
    font-size: 1rem;
    line-height: 1.375rem;
  }
}
