@keyframes :global(hide) {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes :global(slideIn) {
  from { transform: translateX(calc(100% + 2rem)); }
  to { transform: translateX(0); }
}

@keyframes :global(swipeOut) {
  from { transform: translateX(var(--radix-toast-swipe-end-x)); }
  to { transform: translateX(calc(100% + 2rem)); }
}

@keyframes :global(slideUpAndFade) {
  0% { opacity: 0; transform: translateY(2px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes :global(slideUpAndFadeBig) {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes :global(slideDownAndFade) {
  0% { opacity: 0; transform: translateY(-2px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes :global(slideRightAndFade) {
  0% { opacity: 0; transform: translateX(-2px); }
  100% { opacity: 1; transform: translateX(0); }
}

@keyframes :global(slideLeftAndFade) {
  0% { opacity: 0; transform: translateX(2px); }
  100% { opacity: 1; transform: translateX(0); }
}

@keyframes :global(slideLeftAndFadeBig) {
  0% { opacity: 0; transform: translateX(20px); }
  100% { opacity: 1; transform: translateX(0); }
}

@keyframes :global(rotate-90) {
  0% { opacity: 0; transform: rotateZ(90deg); }
  10% { opacity: 0; transform: rotateZ(90deg); }
  25% { opacity: 1; transform: rotateZ(90deg); }
  60% { opacity: 1; transform: rotateZ(0); }
  85% { opacity: 1; transform: rotateZ(0); }
  100% { opacity: 0; transform: rotateZ(0); }
}

@keyframes :global(fadeIn) {
  from { opacity: 0; }
  to { opacity: 1; }
}
